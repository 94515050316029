<template>
    <div class="container">

            <h1 class="title is-size-2">Boks nr {{ unit.countyId }}-{{ unit.serialNumber }}, reol {{ unit.skjellarkivReolId }} </h1>
            <h2 class="subtitle">{{ unit.countyName }}, {{ activityName }}</h2>
            
            <div class="is-pulled-right">
                <button @click="showFormModal()" class="button is-info">
                    <span class="icon is-small">
                    <i class="fa fa-plus"></i>
                </span>
                <span>Legg til prøver</span>
                    </button>
                </div>
            <span class="is-clearfix"></span>
            <h2 class="subtitle">Innhold i boks:</h2>

            <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="has-background-info-light">
                    <tr>
                        <th>Fangstår</th>
                        <th>Vassdrag</th>
                        <th>Type</th>
                        <th>Aktivitet</th>
                        <th>Redskap</th>
                        <th>Løpenr i eske</th>
                        <th><abbr title="Laks">LA</abbr></th>
                        <th><abbr title="Aure">AU</abbr></th>
                        <th><abbr title="Røye">RØ</abbr></th>
                        <th><abbr title="Pukkellaks">PU</abbr></th>
                        <th><abbr title="Regnbueørret">RE</abbr></th>
                        <th><abbr title="Annet">AN</abbr></th>
                        <th>Kommentarer</th>
                        <th v-if="fiskeskjellRolle"></th>
                        <th v-if="fiskeskjellRolle"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(sample) in sampleCollections" :key="sample.id">
                        <td> {{ sample.year }}</td>
                        <td>{{ sample.vassdragNavn }}
                            <span v-if="sample.vassdragId === 0"> {{ sample.vassdragUtenLregnr }} (uten Lregnr) </span>
                        </td>
                        <td> <span>{{ getFishingType(sample.fishingTypeId) }}</span> </td>
                        <td>{{ sample.activity }}</td>
                        
                        <td>{{ sample.tool }}</td>
                        <td>{{ sample.lopenr }}</td>
                        <td>{{ sample.laks }}</td>
                        <td>{{ sample.aure }}</td>
                        <td>{{ sample.regnbueørret }}</td>
                        <td>{{ sample.pukkellaks }}</td>
                        <td>{{ sample.røye }}</td>
                        <td>{{ sample.annet }}</td>
                        <td> {{ sample.comment }} {{ sample.annetText }}</td>
                        <td v-if="fiskeskjellRolle">
                            <a @click="showFormModal(sample.id)">Endre</a>
                        </td>
                        <td v-if="fiskeskjellRolle">
                            <a @click="slettSampleCollection(sample.id)">Slett</a>
                        </td>
                    </tr>
                </tbody>
            </table>

        <form-modal id="formModalProver" ref="formModal" title="Legg til prøver i boks" @submit="handleSubmit">
            <component :is="leggTilProverForm" :model="sampleColl" :fylkeVassdragModel="fylkeVassdrag" :activityTypeId="unit.activityTypeId" ref="formComponent"></component>
        </form-modal>

        <button class="button is-default" @click="$router.push({ name: 'units' })">Tilbake</button>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
</template>

<script>
 import api from "@/api/basicApi.js";
 import { FishingTypeObjectEnum } from "@/enums/FishingTypeEnum";
 import { ActivityTypeArrayEnum } from "../../enums/ActivityTypeEnum";
 import FormModal from '@/components/modals/FormModal.vue';
 import LeggTilProver from './LeggTilProver.vue'
 import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue'
 export default {
     data: function () {
         return {
 
            sampleCollections: [],   
            unit: {},
            leggTilProverForm: LeggTilProver,
            sampleColl: this.initSampleCollection(),
            fishingTypes: FishingTypeObjectEnum,
            user: JSON.parse(localStorage.getItem("user")),
            fylkeVassdrag: {fylkeId: "", vassdragId: null}
         }
     },
     
     components: { "form-modal": FormModal, LeggTilProver, "confirm-dialogue": ConfirmDialogue},
     mounted() {
         let self = this;
         var unitId = this.$route.params.unitId;
         api.fetchData({ url: `Archive/GetStorageUnit/${unitId}` })
                 .then(res => {
                     self.unit = res.data
                     self.fylkeVassdrag.fylkeId = self.unit.countyId.toString()
                 });
        
        self.getSampleCollectionForUnit()
         
     },
     computed: {
         fiskeskjellRolle() {
             return this.user != null && (this.user.accountRoles.includes(1) || this.user.accountRoles.includes(5));
         },
         activityName() {
          if (ActivityTypeArrayEnum.find(a => a.id == this.unit.activityTypeId)){
            return ActivityTypeArrayEnum.find(a => a.id == this.unit.activityTypeId).name
           }
           else {
            return null
           }
         }
     },
     methods: {
        getSampleCollectionForUnit() {
            var unitId = this.$route.params.unitId;
            let self = this 
            api.fetchData({ url: `Archive/GetSampleCollectionForUnit/${unitId}` })
            .then(res => {
                self.sampleCollections = res.data
            });

        },
        handleSubmit() {
            let self = this;
            const formComponent = self.$refs.formComponent;

            formComponent.save(() => {
                self.getSampleCollectionForUnit()
                self.$refs.formModal.close();
            })
        
       },
       showFormModal(id) {
          if (id > 0) {
            let self = this
            api.fetchData({ url: `Archive/GetSampleCollection/${id}` })
                .then(res => {
                    self.sampleColl = res.data
                    self.fylkeVassdrag.vassdragId  = res.data.vassdragId
                });
          } else {
              this.sampleColl = this.initSampleCollection()
              this.fylkeVassdrag.vassdragId = null  
          }

            
            this.$refs.formModal.show();
        },

        initSampleCollection() {
            return {
                laks: 0,
                aure: 0,
                røye: 0,
                pukkellaks: 0,
                regnbueørret: 0,
                annet: 0,
                annetTekst: '',
                storageUnitId: +this.$route.params.unitId
            }
        },

       
         slettSampleCollection(id) {
              let self = this
              this.$refs.confirmDialogue.show({
                  title: 'Slett prøver',
                  message: 'Er du sikker på at du vil slette prøvene?',
                  okButton: 'Slett',
              }).then((result) => {
                  if (result) {
                      api.deleteData({ url: "Archive/DeleteSampleCollection/" + id }).then(function() {
                        self.getSampleCollectionForUnit()
                      })
                  }
              })
         },
         getFishingType(id) {
            if (id > 0) {
             return Object.values(FishingTypeObjectEnum).find(x => x.id == id).name;
            }
         }
     }
    
 };
</script>

<style>
.table td {
    vertical-align: middle;
}

.center {
    margin-top: 35px;
}


</style>